import './index.css'
import CardV2 from '../CardV2'
import SearchBar from '../SearchBar'
import { Loading } from '../Loading'
import { Helmet } from 'react-helmet'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FetchList from '../../utils/api'
import TagSelector from '../TagSelector'
import pinyin from 'pinyin-match'
import GithubLink from '../GithubLink'
import DarkSwitch from '../DarkSwitch'
import { isLogin } from '../../utils/check'
import { generateSearchEngineCard } from '../../utils/serachEngine'
import { toggleJumpTarget } from '../../utils/setting'

const mutiSearch = (s, t) => {
  const source = (s as string).toLowerCase()
  const target = t.toLowerCase()
  const rawInclude = source.includes(target)
  const pinYinInlcude = Boolean(pinyin.match(source, target))
  return rawInclude || pinYinInlcude
}

const Content = (props: any) => {
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [currTag, setCurrTag] = useState('全部')
  const [searchString, setSearchString] = useState('')
  const [val, setVal] = useState('')

  const filteredDataRef = useRef<any>([])

  const showGithub = useMemo(() => {
    const hide = data?.setting?.hideGithub === true
    return !hide
  }, [data])
  const loadData = useCallback(async () => {
    try {
      setLoading(true)
      const r = await FetchList()
      setData(r)
      const tagInLocalStorage = window.localStorage.getItem('tag')
      if (tagInLocalStorage && tagInLocalStorage !== '') {
        if (r?.catelogs && r?.catelogs.includes(tagInLocalStorage)) {
          setCurrTag(tagInLocalStorage)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [setData, setLoading, setCurrTag])
  useEffect(() => {
    loadData()
  }, [loadData])

  const handleSetCurrTag = (tag: string) => {
    setCurrTag(tag)
    // 管理后台不记录了
    if (tag !== '管理后台') {
      window.localStorage.setItem('tag', tag)
    }
    resetSearch(true)
  }

  const resetSearch = (notSetTag?: boolean) => {
    setVal('')
    setSearchString('')
    const tagInLocalStorage = window.localStorage.getItem('tag')
    if (!notSetTag && tagInLocalStorage && tagInLocalStorage !== '' && tagInLocalStorage !== '管理后台') {
      setCurrTag(tagInLocalStorage)
    }
  }

  const handleSetSearch = (val: string) => {
    if (val !== '' && val) {
      setCurrTag('全部')
      setSearchString(val.trim())
    } else {
      resetSearch()
    }
  }

  const filteredData = useMemo(() => {
    if (data.tools) {
      const localResult = data.tools
        .filter((item: any) => {
          const hide = item.hide
          if (!hide) return true
          if (hide && isLogin()) return false
          return false
        })
        .filter((item: any) => {
          if (currTag === '全部') {
            return true
          }
          return item.catelog === currTag
        })
        .filter((item: any) => {
          if (searchString === '') {
            return true
          }
          return mutiSearch(item.name, searchString) || mutiSearch(item.desc, searchString) || mutiSearch(item.url, searchString)
        })
      return [...localResult, ...generateSearchEngineCard(searchString)]
    } else {
      return [...generateSearchEngineCard(searchString)]
    }
  }, [data, currTag, searchString])

  useEffect(() => {
    filteredDataRef.current = filteredData
  }, [filteredData])

  useEffect(() => {
    if (searchString.trim() === '') {
      document.removeEventListener('keydown', onKeyEnter)
    } else {
      document.addEventListener('keydown', onKeyEnter)
    }
    return () => {
      document.removeEventListener('keydown', onKeyEnter)
    }
    // eslint-disable-next-line
  }, [searchString])

  const renderCardsV2 = useCallback(() => {
    return filteredData.map((item, index) => {
      return (
        <CardV2
          title={item.name}
          url={item.url}
          des={item.desc}
          logo={item.logo}
          key={item.id}
          catelog={item.catelog}
          index={index}
          isSearching={searchString.trim() !== ''}
          onClick={() => {
            resetSearch()
            if (item.url === 'toggleJumpTarget') {
              toggleJumpTarget()
              loadData()
            }
          }}
        />
      )
    })
    // eslint-disable-next-line
  }, [filteredData, searchString])

  const onKeyEnter = (ev: KeyboardEvent) => {
    const cards = filteredDataRef.current
    // 使用 keyCode 防止与中文输入冲突
    if (ev.keyCode === 13) {
      if (cards && cards.length) {
        window.open(cards[0]?.url, '_blank')
        resetSearch()
      }
    }
    // 如果按了数字键 + ctrl/meta，打开对应的卡片
    if (ev.ctrlKey || ev.metaKey) {
      const num = Number(ev.key)
      if (isNaN(num)) return
      ev.preventDefault()
      const index = Number(ev.key) - 1
      if (index >= 0 && index < cards.length) {
        window.open(cards[index]?.url, '_blank')
        resetSearch()
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="icon"
          href={data?.setting?.favicon ?? 'favicon.ico'}
        />
        <title>{data?.setting?.title ?? '花窝'}</title>
      </Helmet>
      <div className="topbar">
        <div className="content">
          <SearchBar
            searchString={val}
            setSearchText={(t) => {
              setVal(t)
              handleSetSearch(t)
            }}
          />
          <TagSelector
            tags={data?.catelogs ?? ['全部']}
            currTag={currTag}
            onTagChange={handleSetCurrTag}
          />
        </div>
      </div>
      <div className="content-wraper">
        <div className="content cards">{loading ? <Loading></Loading> : renderCardsV2()}</div>
      </div>
      <div className="record-wraper">
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noreferrer">
          {data?.setting?.govRecord ?? ''}
        </a>
      </div>
      {showGithub && <GithubLink />}
      <DarkSwitch showGithub={showGithub} />
    </>
  )
}

export default Content
